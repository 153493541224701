import React from "react";
import * as Sentry from "@sentry/browser";
import {
  getGeo,
  getUser,
  getLoginDetail,
  getPremium,
  isBrowser,
  isPremium,
} from "../services/auth";

const SentryContext = React.createContext();

const SentrySetup = ({ children, rootContext }) => {
  // Use getCurrentScope() and setUser() instead of configureScope
  if (isBrowser()) {
    // Use an effect-like approach to set user data
    const setupUserData = async () => {
      try {
        const { data, quota } = await getGeo();
        const country = data.country || `not available`;
        const city = data.city || `not available`;
        const id = getUser() || `not logged in`;
        const expires = new Date(getPremium() || `1970-01-01`);
        const premium = isPremium();
        const username = getLoginDetail() || `not logged in`;

        Sentry.setUser({
          city,
          country,
          expires,
          id,
          premium,
          quota,
          username,
        });
      } catch (error) {
        console.error('Error setting Sentry user data:', error);
      }
    };
    
    setupUserData();
  }

  return (
    <SentryContext.Provider value={ rootContext }>
      {children}
    </SentryContext.Provider>
  )
}

export { SentryContext, SentrySetup }

