import * as Comlink from "comlink"
import * as Sentry from "@sentry/browser";

const TTL = 5000
const DAY = 86399993
const isBrowser = () => typeof window !== "undefined"


const clearPremium = () => {
  window.localStorage.removeItem("premium");
  window.localStorage.removeItem("debounce");
}

const login = ({ method }) => {
  if(!isBrowser()) return;
  if(typeof window.gtag !== 'function') return;
  window.gtag("event", "login", {
    method
  });
}

const getGeo = async () => {
  if (!isBrowser()) return;
  const worker = new Worker("/workers/main.js");
  const obj = Comlink.wrap(worker);
  await obj.getGeo();
  const data = await obj.data;
  const supported = await obj.supported;
  const error = await obj.error;
  if (error) Sentry.captureException(error);

  let quota;
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const estimate = await navigator.storage.estimate();
    quota = parseInt(estimate.usage / estimate.quota * 100);
  } else {
    quota = -1;
  }

  return { data, quota, supported };
}

const debounce = async () => {
  if (!isBrowser()) return;
  const worker = new Worker("/workers/main.js");
  const obj = Comlink.wrap(worker);
  await obj.debouncer();
  const debounced = await obj.debounce;
  return debounced
}

const getEmail = () => isBrowser() && getWithExpiry("userEmail");
const getLoginDetail = () => {
  if (!isBrowser()) return
  const user = getWithExpiry("user");
  return (user?.phoneNumber || user?.displayName || user?.email)
}
const getProvider = () => {
  if (!isBrowser()) return
  const user = getWithExpiry("user");
  return user?.providerData[0]?.providerId
}
const getPremium = () => getWithExpiry("premium")
const getUser = () => getWithExpiry("userId");

const isPremiumSet = () => !!(getWithExpiry("premium"))
const isLoggedIn = () => !!(getUser())
const isPremium = () => (getPremium() >= new Date().getTime());

const logout = async (auth, mounted) => {
  if (!mounted.current) return;
  
  // First clear all local storage to prevent state issues
  if (isBrowser()) {
    // Clear specific auth-related items
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("userEmail");
    window.localStorage.removeItem("premium");
    window.localStorage.removeItem("debounce");
    
    // Clear session storage
    window.sessionStorage.clear();
  }
  
  try {
    // Use Firebase v11 signOut method
    if (auth) {
      console.log('Attempting to sign out...');
      try {
        // Import signOut dynamically to avoid circular reference issues
        const { signOut } = await import('firebase/auth');
        await signOut(auth);
        console.log('Firebase signOut completed successfully');
      } catch (signOutError) {
        console.error('Error during Firebase signOut:', signOutError);
      }
    } else {
      console.warn('Auth object is undefined in logout function');
    }
  } catch (error) {
    console.error('Error during logout process:', error);
  }
  
  // Clear IndexedDB Firebase auth persistence
  if (isBrowser()) {
    try {
      const dbNames = ['firebaseLocalStorageDb', 'firebase-heartbeat-database', 'firebase-installations-database'];
      for (const dbName of dbNames) {
        try {
          const deleteRequest = indexedDB.deleteDatabase(dbName);
          deleteRequest.onerror = () => console.error(`Error deleting IndexedDB: ${dbName}`);
          deleteRequest.onsuccess = () => console.log(`Successfully deleted IndexedDB: ${dbName}`);
        } catch (dbError) {
          console.error(`Error with database ${dbName}:`, dbError);
        }
      }
    } catch (e) {
      console.error('Error clearing IndexedDB:', e);
    }
    
    // Use a timeout to ensure the above operations have time to complete
    // before redirecting to avoid freezing
    setTimeout(() => {
      // Redirect to home page instead of reloading to avoid potential loops
      window.location.href = window.location.origin;
    }, 300);
    return;
  }
  
  return false; // Don't reload if we're not in a browser
}

const removeKey = (key) => isBrowser() && window.localStorage.removeItem(key);

const silentLogout = async ({ auth, mounted }) => {
  if (!isBrowser()) return;
  if (!mounted.current) return;
  
  try {
    // Only clear local storage without attempting to sign out
    // This prevents the infinite recursion issue when clicking the login button
    if (isBrowser()) {
      // Clear specific auth-related items
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("userId");
      window.localStorage.removeItem("userEmail");
      window.localStorage.removeItem("premium");
      window.localStorage.removeItem("debounce");
      
      // Clear session storage
      window.sessionStorage.clear();
      
      // Don't attempt to clear IndexedDB here as it can cause delays
      // The actual signOut will happen during the login process
      console.log('Silent logout completed - cleared local storage');
    }
  } catch (error) {
    console.error('Error during silent logout:', error);
  }
}

const setPremium = premium => {
  if (typeof premium !== 'number') return;
  const ttlPremium = premium - new Date().getTime();
  if (!ttlPremium >= TTL) return;
  setWithExpiry("premium", premium, ttlPremium);
}
const setUser = user => {
  if (!isBrowser()) return;
  setWithExpiry("user", user, DAY);
  setWithExpiry("userId", user.uid, DAY);
  setWithExpiry("userEmail", user.email, DAY);
}

const setWithExpiry = (key, value, ttl) => {
  if (!isBrowser()) return;
  const now = new Date().getTime();
  const item = {
    value: value,
    expiry: now + (ttl || TTL),
  }
  window.localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
  if (!isBrowser()) return;
  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  const now = new Date().getTime();

  if (now > item.expiry) {
    window.localStorage.removeItem(key)
    return null
  }

  return item.value
}


export {
  clearPremium,
  debounce,
  getEmail,
  getGeo,
  getLoginDetail,
  getProvider,
  getPremium,
  getUser,
  getWithExpiry,
  isBrowser,
  isLoggedIn,
  isPremium,
  isPremiumSet,
  login,
  logout,
  removeKey,
  setPremium,
  setUser,
  setWithExpiry,
  silentLogout,
}
