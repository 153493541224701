// Firebase v11 modular imports
import { initializeApp } from "@firebase/app";
import { getAuth, onAuthStateChanged, EmailAuthProvider, PhoneAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { login, setPremium, setUser } from "./src/services/auth";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

let auth,
  currentUser,
  firestore,
  functions,
  app,
  timestamp,
  authProviders;

const getFirebase = () => {
  if (typeof window !== 'undefined') {
    if (app) return app;
    try {
      app = initializeApp(firebaseConfig);
    } catch (e) {
      if (e.message.match(/firebase app.+already exists/i))
        window.location.reload()
      else
        throw (e)
    }

    auth = getAuth(app);
    // Remove the custom signOut method to prevent circular references
    // The logout function in auth.js will handle the signOut process directly
    // Note: setPersistence is handled differently in v11, will be set in the auth component
    firestore = getFirestore(app);
    functions = getFunctions(app);
    timestamp = serverTimestamp();

    onAuthStateChanged(auth, (user) => { if (user) currentUser = user });

    // Define auth providers for our custom auth component
    authProviders = {
      emailProvider: EmailAuthProvider,
      phoneProvider: PhoneAuthProvider,
      googleProvider: GoogleAuthProvider,
      twitterProvider: TwitterAuthProvider,
      signInSuccessUrl: `/`,
      signInFlow: `popup`,
      requireDisplayName: false,
      // These will be used by our custom auth component
      handleSignInSuccess: async function(user) {
        const idTokenResult = await user.getIdTokenResult();
        const premium = idTokenResult && idTokenResult.claims && idTokenResult.claims.premium;
        setUser(user);
        setPremium(premium);
        login({ method: user.providerData[0].providerId } );
        return true;
      }
    };
    return app;
  }
  return null;
}

export { auth, firebaseConfig, currentUser, firestore, functions, getFirebase as default, timestamp, authProviders };

